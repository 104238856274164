// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  strapi_server: 'http://172.233.186.58:1337/api',
  strapi_file_library:'http://172.233.186.58:1337',
  twoFactorAuth_server:'http://172.233.186.58:1338',
  report_server:'http://172.233.186.58:1339',
  instance_name: "testing_rog",

  //ROCAR
 // strapi_server: 'https://tallerrocar.cloud:3002/api',
  //strapi_file_library:'https://tallerrocar.cloud:3002',
  //twoFactorAuth_server:'https://tallerrocar.cloud:5051',
  //report_server:'https://tallerrocar.cloud:9091',
  //instance_name: "ROCAR",
  
  s3_bucket:"https://file-storage.us-mia-1.linodeobjects.com/",
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
